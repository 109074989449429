import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type NotificationsTheme = ThemeBranch<{
  TrekNotificationPane: {
    pallete: {
      background: string;
      foreground: string;
      foregroundCTA: string;
      borderHeader: string;
      elevation: string;
    };
    sizes: {
      padding: string;
      paddingHeader: string;
    };
  };
  TrekNotification: {
    pallete: {
      background: string;
      backgroundUnread: string;
      foreground: string;
      borderColor: string;
      backgroundIcon: string;
      borderIcon: string;
      fontColorTimestamp: string;
      backgroundIndicator: string;
      backgroundAvatar: string;
    };
    sizes: {
      padding: string;
      marginIcon: string;
      iconSize: string;
      iconGlyphSize: string;
      marginContentTitle: string;
      marginContentBody: string;
      fontSizeTitle: string;
      fontWeightTitle: string;
      lineHeightTitle: string;
      fontSizeBody: string;
      lineHeightBody: string;
      fontSizeTimestamp: string;
      lineHeightTimestamp: string;
      widthAvatar: string;
      heightAvatar: string;
    };
    position: {
      topAvatar: string;
      leftAvatar: string;
    };
  };
  TrekNotificationPaneFooter: {
    pallete: {
      backgroundCTA: string;
      foregroundCTA: string;
      borderColorCTA: string;
    };
    sizes: {
      height: string;
      width: string;
      margin: string;
      fontSize: string;
      lineHeight: string;
      letterSpacing: string;
    };
  };
  NotificationMessage: {
    pallete: {
      foreground: string;
    };
    sizes: {
      padding: string;
      fontSize: string;
      lineHeight: string;
    };
  };
}>;

export const DefaultTheme: NotificationsTheme = {
  TrekNotificationPane: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorFauxBlack,
      foregroundCTA: colors.colorBlueBase,
      borderHeader: colors.colorGray4,
      elevation: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
    sizes: {
      padding: '0',
      paddingHeader: `0 ${spacers.spacer04}`,
    },
  },
  TrekNotification: {
    pallete: {
      background: colors.colorWhite,
      backgroundHover: colors.colorGray6,
      backgroundUnread: colors.colorBlue6,
      backgroundUnreadHover: colors.colorBlue5,
      foreground: colors.colorFauxBlack,
      borderColor: colors.colorGray4,
      backgroundIcon: colors.colorGray6,
      borderIcon: colors.colorGray4,
      fontColorTimestamp: colors.colorGray2,
      backgroundIndicator: colors.colorBlueBase,
      backgroundAvatar: colors.colorWhite,
    },
    sizes: {
      padding: `${spacers.spacer04} ${spacers.spacer08} ${spacers.spacer04} ${spacers.spacer06}`,
      marginIcon: `0 ${spacers.spacer04} 0 0`,
      iconSize: '48px',
      iconGlyphSize: '24px',
      marginContentTitle: `0 0 ${spacers.spacer02}`,
      marginContentBody: `0 0 ${spacers.spacer01}`,
      fontSizeTitle: '16px',
      fontWeightTitle: '500',
      lineHeightTitle: '24px',
      fontSizeBody: '16px',
      lineHeightBody: '24px',
      fontSizeTimestamp: '12px',
      lineHeightTimestamp: '18px',
      widthAvatar: '24px',
      heightAvatar: '24px',
    },
    position: {
      topAvatar: '36px',
      leftAvatar: '24px',
    },
  },
  TrekNotificationPaneFooter: {
    pallete: {
      backgroundCTA: colors.colorGray6,
      backgroundCTAHover: colors.colorBlack2,
      backgroundCTADisabled: colors.colorBlack5,
      foregroundCTA: colors.colorFauxBlack,
      foregroundCTAHover: colors.colorWhite,
      foregroundCTADisabled: colors.colorWhite,
      borderColorCTA: colors.colorGray4,
    },
    sizes: {
      height: '56px',
      width: '100%',
      margin: `${spacers.spacer05} 0 0`,
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '1px',
    },
  },
  NotificationMessage: {
    pallete: {
      foreground: colors.colorGray2,
    },
    sizes: {
      padding: `${spacers.spacer07} ${spacers.spacer06}`,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
};
