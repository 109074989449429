import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type LabelTheme = ThemeBranch<{
  TrekLabel: {
    pallete: {
      foreground: string;
      errorForeground: string;
    };
    sizes: {
      fontSize: string;
      height: string;
      letterSpacing: string;
      lineHeight: string;
      margin: string;
      iconSize: string;
    };
  };
}>;

export const DefaultTheme: LabelTheme = {
  TrekLabel: {
    pallete: {
      foreground: colors.colorFauxBlack,
      errorForeground: colors.colorRedBase,
      foregroundInfo: colors.colorGray2,
      foregroundDisabled: colors.colorGray2,
    },
    sizes: {
      fontSize: '12px',
      height: '12px',
      letterSpacing: '1px',
      lineHeight: '12px',
      margin: `${spacers.spacer01} 0 ${spacers.spacer02} 0`,
      iconSize: '12px',
    },
  },
};
