import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SideMenuTheme = ThemeBranch<{
  TrekSideMenu: {
    sizes: {
      width: string;
      padding: string;
      marginTop: string;
    };
    pallete: {
      borderColor: string;
    };
  };
  TrekSideMenuItem: {
    sizes: {
      padding: string;
    };
    pallete: {
      color: string;
      colorActive: string;
    };
  };
}>;

export const DefaultTheme: SideMenuTheme = {
  TrekSideMenu: {
    sizes: {
      width: '100%',
      padding: spacers.spacer02,
      marginTop: spacers.spacer09,
    },
    pallete: {
      borderColor: colors.colorBlack9,
    },
  },
  TrekSideMenuItem: {
    sizes: {
      padding: `${spacers.spacer01} ${spacers.spacer03}`,
    },
    pallete: {
      color: colors.colorBlack3,
      colorActive: colors.colorBlueBase,
    },
  },
};
