import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type CommunityLoaderTheme = ThemeBranch<{
  CommunityLoader: {
    pallete: {
      background: string;
      foreground: string;
    };
    sizes: {
      width: string;
      height: string;
      margin: string;
      borderRadius: string;
    };
  };
}>;

export const DefaultTheme: CommunityLoaderTheme = {
  CommunityLoader: {
    pallete: {
      background: colors.colorGray5,
      foreground: colors.colorFauxBlack,
    },
    sizes: {
      width: '60px',
      height: '60px',
      margin: 'auto',
      borderRadius: '50%',
    },
  },
};
