import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SearchFieldTheme = ThemeBranch<{
  TrekSearchField: {
    pallete: {
      foreground: string;
      errorForeground: string;
      borderColor: string;
      hoverBorder: string;
      errorBorder: string;
      disabledBorder: string;
      background: string;
      disabledBackground: string;
      backgroundSubmit: string;
      backgroundSubmitHover: string;
      backgroundSubmitDisabled: string;
    };
    sizes: {
      borderRadius: string;
      fontSize: string;
      padding: string;
      activePadding: string;
      lineHeight: string;
      iconSize: string;
    };
  };
}>;

export const DefaultTheme: SearchFieldTheme = {
  TrekSearchField: {
    pallete: {
      foreground: colors.colorFauxBlack,
      errorForeground: colors.colorRedBase,
      borderColor: colors.colorGray3,
      hoverBorder: colors.colorGray2,
      errorBorder: colors.colorRedBase,
      disabledBorder: colors.colorGray3,
      background: colors.colorWhite,
      disabledBackground: colors.colorGray5,
      backgroundSubmit: colors.colorFauxBlack,
      backgroundSubmitHover: colors.colorBlack2,
      backgroundSubmitDisabled: colors.colorBlack5,
    },
    sizes: {
      borderRadius: '4px',
      fontSize: '14px',
      padding: '9.5px 52px 9.5px 42px',
      activePadding: '9.5px 51px 9.5px 41px',
      lineHeight: '21px',
      iconSize: '24px',
    },
  },
};
