import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SideNavTheme = ThemeBranch<{
  TrekSideNav: {
    pallete: {
      background: string;
      foreground: string;
      elevation: string;
    };
    sizes: {
      expanded: {
        width: string;
        padding: string;
      };
      collapsed: {
        width: string;
        padding: string;
      };
    };
  };
  TrekSideNavContent: {
    sizes: {
      expanded: {
        width: string;
        padding: string;
      };
      collapsed: {
        width: string;
        padding: string;
      };
    };
  };
  TrekSideNavItem: {
    pallete: {
      backgroundIcon: string;
      backgroundHover: string;
      backgroundActive: string;
      colorActive: string;
    };
    sizes: {
      margin: string;
      backgroundIconSize: string;
      iconSize: string;
      expanded: {
        height: string;
        padding: string;
      };
      collapsed: {
        height: string;
        padding: string;
      };
    };
    position: {
      expanded: {
        left: string;
        top: string;
      };
      collapsed: {
        left: string;
      };
    };
  };
  TrekSideNavLabel: {
    pallete: {
      background: string;
      foreground: string;
    };
    font: {
      expanded: {
        size: string;
        weight: string;
      };
      collapsed: {
        size: string;
        weight: string;
      };
    };
    sizes: {
      expanded: {
        padding: string;
      };
      collapsed: {
        padding: string;
      };
    };
    position: {
      left: string;
      top: string;
    };
  };
  TrekSideNavToggle: {
    pallete: {
      background: string;
      iconColor: string;
    };
    sizes: {
      width: string;
      height: string;
    };
    position: {
      bottom: string;
    };
  };
}>;

export const DefaultTheme: SideNavTheme = {
  TrekSideNav: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorFauxBlack,
      elevation: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    },
    sizes: {
      expanded: {
        width: '290px',
        padding: '1.5rem 0',
      },
      collapsed: {
        width: '72px',
        padding: '1.5rem 0',
      },
    },
  },
  TrekSideNavContent: {
    sizes: {
      expanded: {
        width: '290px',
        padding: '0 1rem',
      },
      collapsed: {
        width: '72px',
        padding: '0',
      },
    },
  },
  TrekSideNavItem: {
    pallete: {
      backgroundIcon: colors.colorGray5,
      backgroundHover: colors.colorGray5,
      backgroundActive: colors.colorFauxBlack,
      colorActive: colors.colorWhite,
    },
    sizes: {
      margin: '0 auto 2rem',
      backgroundIconSize: '2.5rem',
      iconSize: '1.5rem',
      expanded: {
        height: '40px',
        padding: '0 0 0 1rem',
      },
      collapsed: {
        height: '40px',
        padding: '0',
      },
    },
    position: {
      expanded: {
        left: '0.5rem',
        top: '0',
      },
      collapsed: {
        left: 'calc(50% - 20px)',
      },
    },
  },
  TrekSideNavLabel: {
    pallete: {
      background: colors.colorGray1,
      foreground: colors.colorWhite,
    },
    font: {
      expanded: {
        size: '14px',
        weight: '500',
      },
      collapsed: {
        size: '12px',
        weight: '600',
      },
    },
    sizes: {
      expanded: {
        padding: '0 0 0 1.5rem',
      },
      collapsed: {
        padding: '0.5rem',
      },
    },
    position: {
      left: '64px',
      top: 'calc(50% - 14px)',
    },
  },
  TrekSideNavToggle: {
    pallete: {
      background: colors.colorWhite,
      iconColor: colors.colorFauxBlack,
    },
    sizes: {
      width: '24px',
      height: '40px',
    },
    position: {
      bottom: '88px',
    },
  },
};
