import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SwitchTheme = ThemeBranch<{
  TrekSwitch: {
    pallete: {
      bead: string;
      beadDisabled: string;
      beadHover: string;
      statusLabelOn: string;
      statusLabelOff: string;
      statusLabelDisabled: string;
      trackOn: string;
      trackOff: string;
      trackDisabled: string;
    };
  };
}>;

export const DefaultTheme: SwitchTheme = {
  TrekSwitch: {
    pallete: {
      bead: colors.colorWhite,
      beadDisabled: colors.colorWhite,
      beadHover: colors.colorGray6,
      statusLabelOn: colors.colorWhite,
      statusLabelOff: colors.colorFauxBlack,
      statusLabelDisabled: colors.colorGray3,
      trackOn: colors.colorFauxBlack,
      trackOff: colors.colorGray4,
      trackDisabled: colors.colorGray5,
    },
  },
};
