import React from 'react';
const style = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
} as React.CSSProperties;

// This is an internal component used to shoew the login form as a fixed modal
const Redirect = ({ baseUrl }: { baseUrl?: string }): JSX.Element => {
  return <iframe title="login" style={style} src={`${baseUrl}/sso_auth`} />;
};

export default Redirect;
