import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type InverseTextTheme = ThemeBranch<{
  TrekInverseText: {
    pallete: {
      background: string;
      foreground: string;
    };
    sizes: {
      fontSize: string;
      margin: string;
      padding: string;
      letterSpacing: string;
    };
  };
}>;

export const DefaultTheme: InverseTextTheme = {
  TrekInverseText: {
    pallete: {
      background: colors.colorFauxBlack,
      foreground: colors.colorWhite,
    },
    sizes: {
      fontSize: '0.75rem',
      margin: '.25rem 0 0 1rem',
      padding: '0.3333rem 0.5rem 0.25rem 0.6em',
      letterSpacing: '0.075rem',
    },
  },
};
