import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type UserIdentifierTheme = ThemeBranch<{
  TrekUserIdentifier: {
    sizes: {
      padding: string;
      fontSize: string;
    };
  };
  TrekProfileAvatar: {
    pallete: {
      background: string;
      foreground: string;
    };
    sizes: {
      fontSize: string;
      width: string;
      height: string;
    };
  };
}>;

export const DefaultTheme: UserIdentifierTheme = {
  TrekUserIdentifier: {
    sizes: {
      padding: '0',
      fontSize: '14px',
      margin: `0 ${spacers.spacer02} 0 0`,
    },
  },
  TrekProfileAvatar: {
    pallete: {
      background: colors.colorGray1,
      foreground: colors.colorWhite,
    },
    sizes: {
      fontSize: '16px',
      width: '30px',
      height: '30px',
      margin: `0 ${spacers.spacer02} 0 0`,
    },
  },
};
