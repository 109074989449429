import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type TextAreaTheme = ThemeBranch<{
  TrekTextArea: {
    pallete: {
      foreground: string;
      errorForeground: string;
      borderColor: string;
      hoverBorder: string;
      errorBorder: string;
      disabledBorder: string;
      background: string;
      disabledBackground: string;
    };
    sizes: {
      borderRadius: string;
      fontSize: string;
      padding: string;
      activePadding: string;
      readPadding: string;
      lineHeight: string;
      minHeight: string;
    };
  };
}>;
export const DefaultTheme: TextAreaTheme = {
  TrekTextArea: {
    pallete: {
      foreground: colors.colorFauxBlack,
      errorForeground: colors.colorRedBase,
      borderColor: colors.colorGray3,
      hoverBorder: colors.colorGray2,
      errorBorder: colors.colorRedBase,
      disabledBorder: colors.colorGray3,
      background: colors.colorWhite,
      disabledBackground: colors.colorGray5,
    },
    sizes: {
      borderRadius: '4px',
      fontSize: '14px',
      padding: `9.5px ${spacers.spacer03} 9.5px ${spacers.spacer03}`,
      activePadding: '8.5px 11px 8.5px 11px',
      readPadding: `8.5px ${spacers.spacer05} 8.5px 11px`,
      lineHeight: '21px',
      minHeight: '40px',
    },
  },
};
