import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type MenuTheme = ThemeBranch<{
  TrekMenu: {
    pallete: {
      background: string;
      elevation: string;
    };
    sizes: {
      padding: string;
      width: string;
      paddingContent: string;
    };
  };
  TrekMenuItem: {
    pallete: {
      backgroundIcon: string;
      backgroundIconHover: string;
      backgroundIconActive: string;
      borderIcon: string;
      borderIconHover: string;
      borderIconActive: string;
      foreground: string;
      foregroundHover: string;
      foregroundActive: string;
    };
    sizes: {
      padding: string;
      margin: string;
      iconSize: string;
      iconGlyphSize: string;
      fontSize: string;
      lineHeight: string;
      width: string;
      height: string;
    };
  };
  TrekMenuHeader: {
    pallete: {
      foreground: string;
      foregroundCTA: string;
      borderColor: string;
    };
    sizes: {
      fontSize: string;
      fontSizeCTA: string;
      padding: string;
      fontWeight: string;
      fontWeightCTA: string;
    };
  };
  TrekMenuFooter: {
    pallete: {
      backgroundCTA: string;
      backgroundCTAHover: string;
      backgroundCTADisabled: string;
      foregroundCTA: string;
      foregroundCTAHover: string;
      foregroundCTADisabled: string;
      borderColorCTA: string;
    };
    sizes: {
      margin: string;
      height: string;
      fontSize: string;
      fontWeight: string;
    };
  };
}>;

export const DefaultTheme: MenuTheme = {
  TrekMenu: {
    pallete: {
      background: colors.colorWhite,
      elevation: `
      0px 2px 4px rgba(0, 0, 0, 0.2),
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12);`,
    },
    sizes: {
      padding: '0',
      width: '480px',
      paddingContent: `${spacers.spacer04} ${spacers.spacer06}`,
    },
  },
  TrekMenuItem: {
    pallete: {
      backgroundIcon: colors.colorGray6,
      backgroundIconHover: colors.colorGray4,
      backgroundIconActive: colors.colorGray1,
      borderIcon: colors.colorGray4,
      borderIconHover: colors.colorGray4,
      borderIconActive: colors.colorGray1,
      foreground: colors.colorFauxBlack,
      foregroundHover: colors.colorFauxBlack,
      foregroundActive: colors.colorWhite,
    },
    sizes: {
      padding: '0',
      margin: `${spacers.spacer04} ${spacers.spacer02}`,
      iconSize: '48px',
      iconGlyphSize: '24px',
      fontSize: '12px',
      lineHeight: '18px',
      width: '128px',
      height: '74px',
    },
  },
  TrekMenuHeader: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundCTA: colors.colorBlueBase,
      borderColor: colors.colorGray4,
    },
    sizes: {
      fontSize: '18px',
      fontSizeCTA: '14px',
      padding: `${spacers.spacer06} ${spacers.spacer06} ${spacers.spacer04} ${spacers.spacer06}`,
      fontWeight: '600',
      fontWeightCTA: '500',
    },
  },
  TrekMenuFooter: {
    pallete: {
      backgroundCTA: colors.colorGray6,
      backgroundCTAHover: colors.colorBlack2,
      backgroundCTADisabled: colors.colorBlack5,
      foregroundCTA: colors.colorFauxBlack,
      foregroundCTAHover: colors.colorWhite,
      foregroundCTADisabled: colors.colorWhite,
      borderColorCTA: colors.colorGray4,
    },
    sizes: {
      margin: '13px 0 0 0',
      height: '56px',
      width: '100%',
      fontSize: '12px',
      fontWeight: '600',
    },
  },
};
