import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

type ItemThemeSizes = {
  iconSize: string;
  fontSize: string;
  fontSizeHeader: string;
  lineHeight: string;
  padding: string;
  sideIndent: string;
  marginContent: string;
  lineHeightHeader?: string;
};

export type MegaMenuTheme = ThemeBranch<{
  TrekMegaMenu: {
    pallete: {
      foreground: string;
      background: string;
      elevation: string;
    };
    sizes: {
      padding: string;
      maxHeight: string;
    };
  };
  TrekMegaMenuItem: {
    pallete: {
      collapsed: {
        foreground: string;
        backgroundIcon: string;
        borderIcon: string;
        border: string;
        hover: string;
      };
      expanded: {
        foreground: string;
        backgroundIcon: string;
        borderIcon: string;
        border: string;
        hover: string;
      };
    };
    sizes: {
      collapsed: ItemThemeSizes;
      expanded: ItemThemeSizes;
    };
  };
  TrekMegaMenuSectionHeader: {
    sizes: {
      fontSize: string;
      lineHeight: string;
      padding: string;
      margin: string;
    };
  };
}>;

export const DefaultTheme: MegaMenuTheme = {
  TrekMegaMenu: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorFauxBlack,
      elevation: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
    sizes: {
      padding: `20px ${spacers.spacer04} ${spacers.spacer04} ${spacers.spacer04}`,
      maxHeight: '562px',
    },
  },
  TrekMegaMenuItem: {
    pallete: {
      collapsed: {
        foreground: colors.colorFauxBlack,
        backgroundIcon: colors.colorGray6,
        borderIcon: colors.colorGray4,
        border: colors.colorGray4,
        hover: colors.colorGray6,
      },
      expanded: {
        foreground: colors.colorGray2,
        backgroundIcon: colors.colorGray6,
        borderIcon: colors.colorGray4,
        border: colors.colorGray4,
        hover: colors.colorGray6,
      },
    },
    sizes: {
      collapsed: {
        iconSize: '48px',
        fontSize: '16px',
        lineHeight: '24px',
        sideIndent: spacers.spacer06,
        marginContent: '0',
        padding: spacers.spacer03,
        fontSizeHeader: '16px',
      },
      expanded: {
        iconSize: '48px',
        fontSize: '12px',
        lineHeight: '18px',
        padding: spacers.spacer05,
        fontSizeHeader: '16px',
        lineHeightHeader: '24px',
        sideIndent: spacers.spacer05,
        marginContent: `0 0 ${spacers.spacer02} 0`,
      },
    },
  },
  TrekMegaMenuSectionHeader: {
    pallete: {
      border: colors.colorGray4,
    },
    sizes: {
      fontSize: '18px',
      lineHeight: '24px',
      padding: `${spacers.spacer04} 0`,
      margin: '0',
    },
  },
};
