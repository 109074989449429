import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type NavBarTheme = ThemeBranch<{
  TrekNavBar: {
    pallete: {
      background: string;
      foreground: string;
      borderColor: string;
    };
    sizes: {
      min: {
        padding: string;
        fontSize: string;
        height: string;
        heightItem: string;
        marginMenuIcon: string;
        marginLogo: string;
        paddingAppName: string;
      };
      med: {
        padding: string;
        fontSize: string;
        height: string;
        heightItem: string;
        marginMenuIcon: string;
        marginLogo: string;
        paddingAppName: string;
      };
      max: {
        padding: string;
        fontSize: string;
        height: string;
        heightItem: string;
        marginMenuIcon: string;
        marginLogo: string;
        paddingAppName: string;
      };
    };
  };
  TrekNavItem: {
    pallete: {
      backgroundIcon: string;
      borderIcon: string;
    };
    sizes: {
      iconSize: string;
    };
  };
}>;

export const DefaultTheme = {
  TrekNavBar: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorGray1,
      borderColor: colors.colorGray4,
    },
    sizes: {
      min: {
        padding: `0 ${spacers.spacer04}`,
        fontSize: '16px',
        height: '48px',
        heightItem: '40px',
        marginMenuIcon: `0 ${spacers.spacer05} 0 0`,
        marginLogo: `0 ${spacers.spacer04} 0 0`,
        paddingAppName: `5px ${spacers.spacer04}`,
      },
      med: {
        padding: `0 ${spacers.spacer04}`,
        fontSize: '16px',
        height: '56px',
        heightItem: '40px',
        marginMenuIcon: `0 ${spacers.spacer05} 0 0`,
        marginLogo: `0 ${spacers.spacer04} 0 0`,
        paddingAppName: `5px ${spacers.spacer04}`,
      },
      max: {
        padding: `0 ${spacers.spacer04}`,
        fontSize: '16px',
        height: '64px',
        heightItem: '40px',
        marginMenuIcon: `0 ${spacers.spacer05} 0 0`,
        marginLogo: `0 ${spacers.spacer04} 0 0`,
        paddingAppName: `5px ${spacers.spacer04}`,
      },
    },
  },
  TrekNavItem: {
    pallete: {
      backgroundIcon: colors.colorGray5,
      backgroundIconActive: colors.colorGray4,
      borderIcon: 'none',
      foreground: colors.colorFauxBlack,
    },
    sizes: {
      heightItem: '48px',
      iconSize: '24px',
      padding: `0 ${spacers.spacer04}`,
      marginItem: `0 ${spacers.spacer02}`,
    },
  },
};
