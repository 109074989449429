import React, { useMemo, PropsWithChildren, useContext } from 'react';
import styled, { DefaultTheme as ThemeType, css } from 'styled-components';
import { theme } from 'styled-tools';
import cx from 'classnames';
import { themeUtils } from '@hulu-react-style-components/util';

const NavContext = themeUtils.NavContext;

const DefaultTheme = themeUtils.Theme.NavBar;

const Item = styled.a<any>`
  display: flex;
  flex-direction: column wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  z-index: 1;
  margin: ${theme('TrekNavItem.sizes.marginItem')};
  width: ${theme('TrekNavItem.sizes.heightItem')};
  height: ${theme('TrekNavItem.sizes.heightItem')};

  &:before {
    content: '';
    width: ${theme('TrekNavItem.sizes.heightItem')};
    height: ${theme('TrekNavItem.sizes.heightItem')};
    position: absolute;
    background: ${theme('TrekNavItem.pallete.backgroundIcon')};
    border: ${theme('TrekNavItem.pallete.borderIcon')};
    border-radius: 100%;
    z-index: -1;
    opacity: 0;
    transform: scale3d(0, 0, 1);
    transition: all 0.135s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  ${(props) =>
    props.active &&
    css`
      &:before {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        background: ${theme('TrekNavItem.pallete.backgroundIconActive')};
      }
    `}

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  > .TrekButton-label {
    display: none;
  }

  > svg.TrekButton-icon {
    height: ${theme('TrekNavItem.sizes.iconSize')};
    width: ${theme('TrekNavItem.sizes.iconSize')};
    margin: 0;
    path {
      fill: ${theme('TrekNavItem.pallete.foreground')};
    }
  }
`;
Item.defaultProps = {
  theme: DefaultTheme,
};

const IconContainer = styled.div<{ $size?: string }>`
  display: flex;
  flex-direction: column no-wrap;
  align-items: center;
  height: ${({ $size }) => theme(`TrekNavBar.sizes.${$size}.height`, '64px')};
  box-shadow: inset 1px 0px 0px 0px ${theme('TrekNavBar.pallete.borderColor')};
  padding: ${theme('TrekNavItem.sizes.padding')};
`;
IconContainer.defaultProps = {
  theme: DefaultTheme,
};

const DropDownContainer = styled.div`
  width: 0;
  height: 0;
  overflow: visible;
`;

interface NavProps extends PropsWithChildren<{}> {
  /** Extend the css classes applied to the component.  */
  className?: string;
  /** Theme to apply when not using context. */
  theme?: ThemeType;
}

export const NavigationMenuContainer = React.forwardRef(
  ({ children, className }: NavProps, ref?: React.Ref<HTMLDivElement>) => {
    return (
      <DropDownContainer className={cx('TrekNavigationMenuContainer-root', className)} ref={ref}>
        {children}
      </DropDownContainer>
    );
  }
);
NavigationMenuContainer.displayName = 'Hulu:NavigationMenuContainer';

export const Navigation = React.forwardRef(
  ({ children, className, theme, ...props }: NavProps, ref?: React.Ref<HTMLDivElement>) => {
    const size = useContext(NavContext);

    const { icons, menu } = useMemo(
      () =>
        React.Children.toArray(children).reduce(
          (result: any, item: any) => {
            if (item.type.displayName === NavigationMenuContainer.displayName) {
              result.menu = item;
            } else {
              result.icons.push(
                React.cloneElement(item, {
                  ...item.props,
                  as: Item,
                  $size: size,
                  variant: 'transparent',
                  // we need to forward theme here as cloneElement doesn't keep context
                  theme: theme || DefaultTheme,
                  className: cx('TrekNavigationItem-root', item.props.className),
                })
              );
            }
            return result;
          },
          { icons: [], menu: null }
        ),
      [children, size, theme]
    );

    if (icons.length > 5) {
      throw new Error('Navigation takes up to 5 icons');
    }

    return (
      <IconContainer $size={size} className={cx('TrekNavigation-root', className)} {...props} ref={ref}>
        {icons}
        {menu}
      </IconContainer>
    );
  }
);
