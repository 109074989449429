import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SearchInputTheme = ThemeBranch<{
  TrekSearchInput: {
    pallete: {
      borderColor: string;
      foreground: string;
    };
    sizes: {
      fontSize: string;
      padding: string;
      paddingInput: string;
    };
  };
}>;

export const DefaultTheme: SearchInputTheme = {
  TrekSearchInput: {
    pallete: {
      borderColor: colors.colorGray3,
      foreground: colors.colorFauxBlack,
    },
    sizes: {
      fontSize: '16px',
      padding: '0 0.5rem',
      paddingInput: '.5rem',
    },
  },
};
