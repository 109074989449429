import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type RadioTheme = ThemeBranch<{
  TrekRadio: {
    pallete: {
      background: string;
      border: string;
      borderHover: string;
      borderHoverErr: string;
      mark: string;
      markDisabled: string;
      markErr: string;
    };
  };
}>;

export const DefaultTheme: RadioTheme = {
  TrekRadio: {
    pallete: {
      background: colors.colorWhite,
      border: colors.colorGray3,
      borderErr: colors.colorRed1,
      borderHover: colors.colorFauxBlack,
      borderHoverErr: colors.colorFauxBlack,
      mark: colors.colorFauxBlack,
      markDisabled: colors.colorGray3,
      markErr: colors.colorRed1,
    },
  },
};
