import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type ChipTheme = ThemeBranch<{
  TrekChip: {
    pallete: {
      default: {
        normal: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
        };
        outlined: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
          borderColor: string;
        };
      };
      primary: {
        normal: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
        };
        outlined: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
          borderColor: string;
        };
      };
      secondary: {
        normal: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
        };
        outlined: {
          color: string;
          backgroundColor: string;
          hoverBackgroundColor: string;
          borderColor: string;
        };
      };
    };
    sizes: {
      medium: {
        padding: string;
        paddingNoIcon: string;
        iconSize: string;
      };
      small: {
        padding: string;
        paddingNoIcon: string;
        iconSize: string;
      };
    };
  };
}>;

export const DefaultTheme: ChipTheme = {
  TrekChip: {
    pallete: {
      default: {
        normal: {
          color: colors.colorFauxBlack,
          backgroundColor: colors.colorGray4,
          hoverBackgroundColor: colors.colorGray3,
        },
        outlined: {
          color: colors.colorFauxBlack,
          backgroundColor: 'transparent',
          hoverBackgroundColor: colors.colorGray5,
          borderColor: colors.colorGray4,
        },
      },
      primary: {
        normal: {
          color: colors.colorFauxBlack,
          backgroundColor: colors.colorGreen1,
          hoverBackgroundColor: colors.colorGreenBase,
        },
        outlined: {
          color: colors.colorFauxBlack,
          backgroundColor: 'transparent',
          hoverBackgroundColor: colors.colorGreen5,
          borderColor: colors.colorGreen1,
        },
      },
      secondary: {
        normal: {
          color: colors.colorWhite,
          backgroundColor: colors.colorBlue1,
          hoverBackgroundColor: colors.colorBlueBase,
        },
        outlined: {
          color: colors.colorBlue1,
          backgroundColor: 'transparent',
          hoverBackgroundColor: colors.colorBlue5,
          borderColor: colors.colorBlue1,
        },
      },
    },
    sizes: {
      medium: {
        padding: '6px',
        paddingNoIcon: `${spacers.spacer03}`,
        iconSize: '20px',
      },
      small: {
        padding: `${spacers.spacer01}`,
        paddingNoIcon: `${spacers.spacer02}`,
        iconSize: '16px',
      },
    },
  },
};

export default DefaultTheme;
