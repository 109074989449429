import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type MultiInputTheme = ThemeBranch<{
  MultiInputItemSelected: {
    pallete: {
      background: string;
      foregroundHover: string;
    };
    sizes: {
      closeSize: string;
      closeMargin: string;
    };
  };
}>;

export const DefaultTheme: MultiInputTheme = {
  MultiInputItemSelected: {
    pallete: {
      background: colors.colorGray5,
      foregroundHover: colors.colorGray2,
    },
    sizes: {
      closeSize: '14px',
      closeMargin: `0 ${spacers.spacer01}`,
    },
  },
};
