import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type ProfileMenuTheme = ThemeBranch<{
  TrekProfileMenu: {
    pallete: {
      background: string;
      elevation: string;
    };
    sizes: {
      paddingContent: string;
      fontSize: string;
      width: string;
    };
  };
  TrekProfileMenuItem: {
    pallete: {
      borderColor: string;
      backgroundHover: string;
      foreground: string;
      foregroundHover: string;
    };
    sizes: {
      iconSize: string;
      paddingIcon: string;
      marginIcon: string;
      padding: string;
      lineHeight: string;
      fontWeight: string;
    };
  };
  TrekProfileMenuHeader: {
    pallete: {
      foreground: string;
      foregroundCTA: string;
      borderColor: string;
    };
    sizes: {
      fontSize: string;
      fontSizeCTA: string;
      padding: string;
      fontWeight: string;
      fontWeightCTA: string;
    };
  };
  TrekProfileMenuFooter: {
    pallete: {
      backgroundCTA: string;
      backgroundCTAHover: string;
      backgroundCTADisabled: string;
      foregroundCTA: string;
      foregroundCTAHover: string;
      foregroundCTADisabled: string;
      borderColorCTA: string;
    };
    sizes: {
      margin: string;
      height: string;
      fontSize: string;
      fontWeight: string;
    };
  };
}>;

export const DefaultTheme: ProfileMenuTheme = {
  TrekProfileMenu: {
    pallete: {
      background: colors.colorWhite,
      elevation: `0px 2px 4px rgba(0, 0, 0, 0.2),
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12);
        `,
    },
    sizes: {
      paddingContent: '0',
      fontSize: '16px',
      width: '396px',
    },
  },
  TrekProfileMenuItem: {
    pallete: {
      borderColor: colors.colorGray4,
      backgroundHover: colors.colorGray6,
      foreground: colors.colorFauxBlack,
      foregroundHover: colors.colorFauxBlack,
    },
    sizes: {
      iconSize: '24px',
      lineHeight: '24px',
      paddingIcon: '0',
      marginIcon: '0',
      padding: `${spacers.spacer03} ${spacers.spacer06}`,
      fontWeight: 'normal',
    },
  },
  TrekProfileMenuHeader: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundCTA: colors.colorBlueBase,
      borderColor: colors.colorGray4,
    },
    sizes: {
      fontSize: '18px',
      fontSizeCTA: '14px',
      padding: `${spacers.spacer06} ${spacers.spacer06} ${spacers.spacer04} ${spacers.spacer06}`,
      fontWeight: '600',
      fontWeightCTA: '500',
    },
  },
  TrekProfileMenuFooter: {
    pallete: {
      backgroundCTA: colors.colorGray6,
      backgroundCTAHover: colors.colorBlack2,
      backgroundCTADisabled: colors.colorBlack5,
      foregroundCTA: colors.colorFauxBlack,
      foregroundCTAHover: colors.colorWhite,
      foregroundCTADisabled: colors.colorWhite,
      borderColorCTA: colors.colorGray4,
    },
    sizes: {
      margin: '13px 0 0 0',
      height: '56px',
      fontSize: '12px',
      fontWeight: '600',
    },
  },
};
