import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type CheckboxTheme = ThemeBranch<{
  TrekCheckbox: {
    pallete: {
      background: string;
      backgroundChecked: string;
      backgroundDisabled: string;
      foreground: string;
      foregroundDisabled: string;
      border: string;
      hoverBorder: string;
      hoverBorderChecked: string;
    };
  };
}>;

export const DefaultTheme: CheckboxTheme = {
  TrekCheckbox: {
    pallete: {
      background: colors.colorWhite,
      backgroundChecked: colors.colorFauxBlack,
      backgroundDisabled: colors.colorGray3,
      foreground: colors.colorWhite,
      foregroundDisabled: colors.colorWhite,
      border: colors.colorGray3,
      borderChecked: colors.colorFauxBlack,
      hoverBorder: colors.colorFauxBlack,
      hoverBorderChecked: colors.colorGray3,
    },
  },
};
