import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type HelpTextTheme = ThemeBranch<{
  TrekHelpText: {
    pallete: {
      errorForeground: string;
      foreground: string;
    };
    sizes: {
      fontSize: string;
      margin: string;
      letterSpacing: string;
      lineHeight: string;
    };
  };
}>;

export const DefaultTheme: HelpTextTheme = {
  TrekHelpText: {
    pallete: {
      errorForeground: colors.colorRedBase,
      foreground: colors.colorGray2,
    },
    sizes: {
      fontSize: '12px',
      margin: `${spacers.spacer01} 0`,
      letterSpacing: '0',
      lineHeight: '18px',
    },
  },
};

export default DefaultTheme;
