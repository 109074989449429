import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type CommunityBannerTheme = ThemeBranch<{
  CommunityBannerOverlay: {
    pallete: {
      background: string;
    };
  };
  CommunityBannerPagination: {
    pallete: {
      success: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      warning: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      info: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      error: {
        background: string;
        foreground: string;
        borderColor: string;
      };
    };
  };
  CommunityBannerAlert: {
    sizes: {
      padding: string;
      maxWidth: string;
    };
  };
}>;

export const DefaultTheme: CommunityBannerTheme = {
  CommunityBannerOverlay: {
    pallete: {
      background: colors.colorBlack5,
    },
  },
  CommunityBannerPagination: {
    pallete: {
      success: {
        background: colors.colorGreen6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorGreenBase,
      },
      warning: {
        background: colors.colorYellow6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorYellowBase,
      },
      info: {
        background: colors.colorBlue6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorBlueBase,
      },
      error: {
        background: colors.colorRed6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorRedBase,
      },
    },
  },
  CommunityBannerAlert: {
    sizes: {
      padding: '10px',
      maxWidth: '100%',
    },
  },
};

export default DefaultTheme;
