import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type SearchResultsTheme = ThemeBranch<{
  TrekSearchResults: {
    pallete: {
      background: string;
      elevation: string;
    };
    sizes: {
      fontSize: string;
      padding: string;
      paddingBottom: string;
    };
  };
  TrekSearchResultsItem: {
    pallete: {
      background: string;
      foreground: string;
      borderColor: string;
      hoverColor: string;
    };
    sizes: {
      padding: string;
      lineHeight: string;
      sideIndent: string;
    };
  };
  TrekSearchResultsHeader: {
    sizes: {
      fontSize: string;
      lineHeight: string;
      fontSizeCTA: string;
      lineHeightCTA: string;
    };
    pallete: {
      foregroundCTA: string;
    };
  };
}>;

export const DefaultTheme: SearchResultsTheme = {
  TrekSearchResults: {
    pallete: {
      background: colors.colorWhite,
      elevation:
        '0px 6px 10px 0px rgba(99, 110, 133, 0.14), 0px 1px 18px 0px rgba(99, 110, 133, 0.12), 0px 3px 5px -1px rgba(99, 110, 133, 0.2)',
    },
    sizes: {
      fontSize: '16px',
      padding: `${spacers.spacer04} 0 0 0`,
      paddingBottom: `${spacers.spacer03}`,
    },
  },
  TrekSearchResultsItem: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorFauxBlack,
      borderColor: colors.colorGray4,
      hoverColor: colors.colorGray6,
    },
    sizes: {
      padding: `1rem ${spacers.spacer06} 1rem ${spacers.spacer06}`,
      lineHeight: '24px',
      sideIndent: '32px',
    },
  },
  TrekSearchResultsHeader: {
    sizes: {
      fontSize: '18px',
      lineHeight: '24px',
      fontSizeCTA: '14px',
      lineHeightCTA: '20px',
    },
    pallete: {
      foregroundCTA: colors.colorBlueBase,
    },
  },
};
