import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type CommunityModalTheme = ThemeBranch<{
  CommunityModalContainer: {
    pallete: {
      background: string;
    };
  };
  CommunityModalContent: {
    pallete: {
      background: string;
      borderColor: string;
    };
    sizes: {
      padding: string;
      margin: string;
      width: string;
    };
  };
  CommunityModalButton: {
    sizes: {
      fontSize: string;
    };
    pallete: {
      foreground: string;
      foregroundHoverDisabled: string;
    };
  };
}>;

export const DefaultTheme: CommunityModalTheme = {
  CommunityModalContainer: {
    pallete: {
      background: colors.colorBlack5,
    },
  },
  CommunityModalContent: {
    pallete: {
      background: colors.colorWhite,
      borderColor: colors.colorGray3,
    },
    sizes: {
      padding: `${spacers.spacer04}`,
      margin: '12% auto 0',
      width: '30%',
    },
  },
  CommunityModalButton: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundHoverDisabled: colors.colorGray3,
    },
    sizes: {
      fontSize: '28px',
    },
  },
};
