import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type TabNavigationTheme = ThemeBranch<{
  TrekTabNavigationTab: {
    pallete: {
      foreground: string;
      foregroundActive: string;
    };
    sizes: {
      fontSize: string;
      padding: string;
      height: string;
      margin: string;
      lineHeight: string;
      lineHeightActive: string;
      fontWeight: string;
    };
  };
}>;

export const DefaultTheme: TabNavigationTheme = {
  TrekTabNavigationTab: {
    pallete: {
      foreground: colors.colorGray2,
      foregroundActive: colors.colorFauxBlack,
    },
    sizes: {
      fontSize: '14px',
      height: '44px',
      margin: '0 5px',
      padding: `9px ${spacers.spacer03} 13px ${spacers.spacer03}`,
      lineHeight: '14px',
      lineHeightActive: '22px',
      fontWeight: '500',
    },
  },
};
