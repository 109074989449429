import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type AccountMenuTheme = ThemeBranch<{
  TrekAccountMenu: {
    pallete: {
      borderColor: string;
    };
    sizes: {
      padding: string;
      fontSize: string;
      iconSize: string;
    };
  };
}>;

export const DefaultTheme: AccountMenuTheme = {
  TrekAccountMenu: {
    pallete: {
      borderColor: colors.colorGray4,
    },
    sizes: {
      padding: `13px 0 13px ${spacers.spacer04}`,
      fontSize: '14px',
      iconSize: '24px',
    },
  },
};

export default DefaultTheme;
