import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type AlertTheme = ThemeBranch<{
  TrekAlert: {
    pallete: {
      success: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      warning: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      info: {
        background: string;
        foreground: string;
        borderColor: string;
      };
      error: {
        background: string;
        foreground: string;
        borderColor: string;
      };
    };
    sizes: {
      padding: string;
      maxWidth: string;
    };
  };
  TrekAlertHeader: {
    sizes: {
      iconSize: string;
      margin: string;
      marginTitle: string;
      marginCTA: string;
    };
  };
  TrekAlertBody: {
    sizes: {
      padding: string;
      marginContent: string;
    };
  };
}>;

export const DefaultTheme: AlertTheme = {
  TrekAlert: {
    pallete: {
      success: {
        background: colors.colorGreen6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorGreenBase,
      },
      warning: {
        background: colors.colorYellow6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorYellowBase,
      },
      info: {
        background: colors.colorBlue6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorBlueBase,
      },
      error: {
        background: colors.colorRed6,
        foreground: colors.colorFauxBlack,
        borderColor: colors.colorRedBase,
      },
    },
    sizes: {
      padding: spacers.spacer05,
      maxWidth: '400px',
    },
  },
  TrekAlertHeader: {
    sizes: {
      iconSize: '24px',
      margin: ` 0 0 ${spacers.spacer04} 0`,
      marginTitle: `0 ${spacers.spacer02} 0  ${spacers.spacer04}`,
      marginCTA: `0 ${spacers.spacer01} 0 0`,
    },
  },
  TrekAlertBody: {
    sizes: {
      padding: `0 ${spacers.spacer07}`,
      marginContent: `${spacers.spacer02} 0 0 0`,
    },
  },
};

export default DefaultTheme;
