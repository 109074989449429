import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type TooltipTheme = ThemeBranch<{
  TrekTooltip: {
    sizes: {
      padding: string;
      fontSize: string;
      lineHeight: string;
      width: string;
    };
    pallete: {
      background: string;
      foreground: string;
    };
  };
  TrekIndicator: {
    pallete: {
      background: string;
    };
  };
}>;

export const DefaultTheme: TooltipTheme = {
  TrekTooltip: {
    sizes: {
      padding: '1.5rem 1.625rem',
      fontSize: '0.875rem',
      lineHeight: '21px',
      width: '16rem',
    },
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorBlack1,
    },
  },
  TrekIndicator: {
    pallete: {
      background: colors.colorWhite,
    },
  },
};
