import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type ButtonTheme = ThemeBranch<{
  TrekButton: {
    pallete: {
      normal: {
        default: string;
        foreground: string;
        hover: string;
        disabled: string;
        disabledOpacity: string | number;
      };
      outlined: {
        default: string;
        foreground: string;
        hover: string;
        disabled: string;
        disabledOpacity: string | number;
      };
      transparent: {
        default: string;
        foreground: string;
        hover: string;
        disabled: string;
        disabledOpacity: string | number;
      };
    };
    sizes: {
      large: {
        padding: string;
        fontSize: string;
        iconSize: string;
        borderRadius: string;
        margin: string;
      };
      medium: {
        padding: string;
        fontSize: string;
        iconSize: string;
        borderRadius: string;
        margin: string;
      };
      regular: {
        padding: string;
        fontSize: string;
        iconSize: string;
        borderRadius: string;
        margin: string;
      };
      small: {
        padding: string;
        fontSize: string;
        iconSize: string;
        borderRadius: string;
        margin: string;
      };
    };
  };
  TrekIconButton: {
    sizes: {
      width: string;
      height: string;
    };
  };
}>;

export const DefaultTheme: ButtonTheme = {
  TrekButton: {
    pallete: {
      normal: {
        default: colors.colorFauxBlack,
        foreground: colors.colorWhite,
        hover: colors.colorBlack2,
        disabled: colors.colorBlack5,
        disabledOpacity: 0.5,
      },
      outlined: {
        default: 'transparent',
        foreground: colors.colorFauxBlack,
        hover: colors.colorBlack4,
        disabled: colors.colorBlack7,
        disabledOpacity: 0.3,
      },
      transparent: {
        default: 'transparent',
        foreground: colors.colorFauxBlack,
        hover: 'transparent',
        disabled: 'transparent',
        disabledOpacity: 0.3,
      },
    },
    sizes: {
      large: {
        padding: `15px ${spacers.spacer07}`,
        paddingTextOnly: `21px ${spacers.spacer07}`,
        fontSize: '12px',
        iconSize: '24px',
        iconMargin: spacers.spacer02,
        borderRadius: '4px',
        margin: '6px',
      },
      medium: {
        padding: `11px ${spacers.spacer06}`,
        paddingTextOnly: `13px ${spacers.spacer06}`,
        fontSize: '12px',
        iconSize: '16px',
        iconMargin: spacers.spacer02,
        borderRadius: '4px',
        margin: '6px',
      },
      regular: {
        padding: `7px ${spacers.spacer05}`,
        paddingTextOnly: `9px ${spacers.spacer05}`,
        fontSize: '12px',
        iconSize: '16px',
        iconMargin: spacers.spacer02,
        borderRadius: '4px',
        margin: '6px',
      },
      small: {
        padding: `6px ${spacers.spacer04}`,
        paddingTextOnly: `6px ${spacers.spacer04}`,
        fontSize: '12px',
        iconSize: '12px',
        iconMargin: spacers.spacer02,
        borderRadius: '4px',
        margin: '6px',
      },
    },
  },
  TrekIconButton: {
    sizes: {
      width: '40px',
      height: '40px',
    },
  },
};

export default DefaultTheme;
