import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type MonochromeLogoTheme = ThemeBranch<{
  TrekMonochromeLogo: {
    pallete: {
      foreground: string;
    };
  };
}>;

export const DefaultTheme: MonochromeLogoTheme = {
  TrekMonochromeLogo: {
    pallete: {
      foreground: colors.colorGray1,
    },
  },
};
