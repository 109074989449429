import React, { PropsWithChildren, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import cx from 'classnames';

import { themeUtils } from '@hulu-react-style-components/util';
import MonochromeLogo from '@hulu-react-style-components/monochrome-logo';
const NavContext = themeUtils.NavContext;

export interface NavProps {
  /** String that represents the app name displayed in top left corner. */
  appName: string;
  /** Menu items inserted at left. */
  leftChildren?: React.ReactNode;
  /** Menu items inserted at right. */
  rightChildren?: React.ReactNode;
  /** Extend the css classes applied to the component.  */
  className?: string;
  /** The navigation bar size. */
  size?: 'min' | 'med' | 'max';
  /** Logo to display. */
  logo?: React.ReactNode;
  [key: string]: any;
}

type NavItemProps = Pick<NavProps, 'size'> & { grow?: boolean };

const DefaultTheme = themeUtils.Theme.NavBar;

const padding = ({ size }: NavItemProps) => theme(`TrekNavBar.sizes.${size}.padding`);
const height = ({ size }: NavItemProps) => theme(`TrekNavBar.sizes.${size}.height`);
const BarComponent = styled.nav<NavProps>`
  position: sticky;
  width: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .TrekNavBar-content {
    display: flex;
    margin: 0;
    padding: ${padding};
    flex-grow: 0;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    border-bottom: 1px solid ${theme('TrekNavBar.pallete.borderColor')};
    background: ${theme('TrekNavBar.pallete.background')};
    color: ${theme('TrekNavBar.pallete.foreground')};
    height: ${height};
  }
`;
BarComponent.defaultProps = {
  theme: DefaultTheme,
};

const BarItem = styled.li<NavItemProps>`
  display: flex;
  align-items: center;
  padding: 0;
  height: ${height};
  list-style: none;
  white-space: nowrap;

  ${ifProp('grow', `flex-grow: 1;`)}

  &:last-child {
    padding: 0;
  }

  &.TrekNavBar-menuIcon {
    justify-content: center;
    height: ${({ size }) => theme(`TrekNavBar.sizes.${size}.heightItem`)};
    width: ${({ size }) => theme(`TrekNavBar.sizes.${size}.heightItem`)};
    margin: ${({ size }) => theme(`TrekNavBar.sizes.${size}.marginMenuIcon`)};
  }

  &.TrekNavBar-logoContainer {
    height: ${({ size }) => theme(`TrekNavBar.sizes.${size}.heightItem`)};
    margin-right: ${({ size }) => theme(`TrekNavBar.sizes.${size}.heightItem`)};
  }

  .TrekNavBar-logo {
    margin: ${({ size }) => theme(`TrekNavBar.sizes.${size}.marginLogo`)};
  }

  .TrekTabNavigation-root {
    height: ${height};
    align-items: end;
    display: flex;
  }
`;
BarItem.defaultProps = {
  theme: DefaultTheme,
};

const BarLink = styled.a`
  align-items: center;
  display: flex;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

const AppName = styled.div<Partial<NavItemProps>>`
  align-items: center;
  justify-content: center;
  padding: ${({ size }) => theme(`TrekNavBar.sizes.${size}.paddingAppName`)};
  background: ${theme('TrekNavBar.pallete.foreground')};
  color: ${theme('TrekNavBar.pallete.background')};
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
`;
AppName.defaultProps = {
  theme: DefaultTheme,
};

const NavBar: React.ForwardRefExoticComponent<NavProps> = React.forwardRef(
  (
    { appName, leftChildren, rightChildren, className, size = 'max', logo = <MonochromeLogo />, ...props }: NavProps,
    ref?: React.Ref<HTMLElement>
  ) => {
    return (
      <NavContext.Provider value={size}>
        <BarComponent className={cx('TrekNavBar-root', className)} size={size} {...props} ref={ref}>
          <ul className="TrekNavBar-content">
            {getLeftChildren(leftChildren, { size })}
            <Logos appName={appName} size={size} logo={logo} />
            {getRightChildren(rightChildren, { size })}
          </ul>
        </BarComponent>
      </NavContext.Provider>
    );
  }
);

const Logos = ({ appName, size, logo }: { appName: string; logo: React.ReactNode } & Partial<NavItemProps>) => {
  return (
    <BarItem className={cx('TrekNavBar-logoContainer', 'BarItem-Logos')} size={size}>
      <BarLink href="/" className="TrekNavBar-logoLink">
        <div className="TrekNavBar-logo">{logo}</div>
        <AppName size={size} className="TrekNavBar-appName">
          {appName}
        </AppName>
      </BarLink>
    </BarItem>
  );
};

const getLeftChildren = (nodes: React.ReactNode, { size }: NavItemProps) => {
  return React.Children.map(nodes, (node) => {
    return (
      <ListItem size={size} className="TrekNavBar-menuIcon">
        {node}
      </ListItem>
    );
  });
};

const getRightChildren = (nodes: React.ReactNode, { size }: NavItemProps) => {
  return React.Children.map(nodes, (node, index) => {
    return (
      <ListItem size={size} grow={index === 0}>
        {node}
      </ListItem>
    );
  });
};

const ListItem = ({
  children,
  grow,
  className,
  size,
}: PropsWithChildren<NavItemProps & HTMLAttributes<HTMLLIElement>>) => {
  return (
    <BarItem className={cx('TrekNavBarItem-root', className)} grow={grow} size={size}>
      {children}
    </BarItem>
  );
};

export default NavBar;
