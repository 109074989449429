import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type DropDownTheme = ThemeBranch<{
  TrekDropDown: {
    pallete: {
      foreground: string;
      foregroundError: string;
      borderColor: string;
      borderHover: string;
      borderError: string;
      borderDisabled: string;
      background: string;
      backgroundDisabled: string;
      elevation: string;
    };
    sizes: {
      borderRadius: string;
      fontSize: string;
      height: string;
      padding: string;
      activePadding: string;
      maxWidth: string;
      maxHeightPopup: string;
      arrowSize: string;
      scrollbarWidth: string;
    };
  };
  TrekDropDownItem: {
    pallete: {
      foreground: string;
      foregroundHover: string;
      foregroundActive: string;
      borderColor: string;
      borderHover: string;
      background: string;
      backgroundHover: string;
      backgroundActive: string;
      foregroundSubtext: string;
    };
    sizes: {
      fontSize: string;
      fontSizeSubtext: string;
      marginSubtext: string;
      lineHeight: string;
      sideIndent: string;
      groupedSideIndent: string;
      groupedItemSideIndent: string;
      padding: string;
    };
  };
}>;

export const DefaultTheme: DropDownTheme = {
  TrekDropDown: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundError: colors.colorRedBase,
      borderColor: colors.colorGray3,
      borderHover: colors.colorGray2,
      borderError: colors.colorRedBase,
      borderDisabled: colors.colorGray3,
      background: colors.colorWhite,
      backgroundDisabled: colors.colorGray5,
      foregroundDisabled: colors.colorGray2,
      elevation: `
      0px 2px 1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 1px 3px rgba(0, 0, 0, 0.12)
      `,
    },
    sizes: {
      borderRadius: '4px',
      fontSize: '14px',
      height: '40px',
      padding: `9.5px ${spacers.spacer03} 9.5px ${spacers.spacer03}`,
      activePadding: '9.5px 11px 9.5px 11px',
      maxWidth: '360px',
      maxHeightPopup: '300px',
      arrowSize: '24px',
      scrollbarWidth: '4px',
    },
  },
  TrekDropDownItem: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundHover: colors.colorFauxBlack,
      foregroundActive: colors.colorFauxBlack,
      borderColor: colors.colorGray4,
      borderHover: colors.colorGray4,
      background: colors.colorWhite,
      backgroundHover: colors.colorGray6,
      backgroundActive: colors.colorBlue6,
      foregroundSubtext: colors.colorGray2,
    },
    sizes: {
      fontSize: '14px',
      fontSizeSubtext: '12px',
      marginSubtext: `0 0 0 ${spacers.spacer02}`,
      lineHeight: '21px',
      sideIndent: spacers.spacer04,
      groupedSideIndent: spacers.spacer05,
      groupedItemSideIndent: spacers.spacer02,
      padding: `13.5px ${spacers.spacer05}`,
    },
  },
};
