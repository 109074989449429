import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type PillTheme = ThemeBranch<{
  TrekPill: {
    pallete: {
      background: string;
      foreground: string;
    };
    sizes: {
      fontSize: string;
      lineHeight: string;
      letterSpacing: string;
    };
  };
}>;

export const DefaultTheme: PillTheme = {
  TrekPill: {
    pallete: {
      background: colors.colorWhite,
      foreground: colors.colorFauxBlack,
    },
    sizes: {
      fontSize: '0.75rem',
      letterSpacing: '0.054rem',
      lineHeight: '0.8125rem',
    },
  },
};
