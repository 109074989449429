import React, { useMemo } from 'react';

// useNamed hook returns a named component from an import
// list of components if provided named parameter is a string
// otherwise it checks if the named parameter is itself
// a component and returns it
export const useNamed = (components: any, named: any): React.ComponentType | any => {
  return useMemo(() => {
    switch (typeof named) {
      case 'string':
        const Component = (components as any)[named];
        if (!Component) {
          throw new Error(`property: ${named} not found`);
        }
        return Component;
      case 'object':
        if (!React.isValidElement(named)) {
          throw new Error('property should be either string or Component');
        }
        return named.type;
      case 'function':
        if (!named.prototype && !React.isValidElement(named())) {
          throw new Error('property should be either string or Component');
        }
        return named;
      default:
        throw new Error('property should be either string or Component');
    }
  }, [components, named]);
};
