import React, { PropsWithChildren } from 'react';
import styled, { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { theme } from 'styled-tools';
import { themeUtils } from '@hulu-react-style-components/util';

const DefaultTheme = themeUtils.Theme.Typography;

export type Variants =
  | 'h1'
  | 'h2'
  | 'h2Medium'
  | 'h3'
  | 'h3Semibold'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'eyebrow'
  | 'button'
  | 'badge'
  | 'navigation'
  | 'caption1'
  | 'caption2'
  | 'legal1'
  | 'legal2'
  | 'link1'
  | 'link2'
  | 'link3';

export interface TypographyProps extends PropsWithChildren<{}> {
  /** The variant to use. */
  variant: Variants;
  /** The desired element to render. */
  component?: string | React.ComponentType<any>;
  [key: string]: any;
}

const variantMapping: Record<Variants, string> = {
  h1: 'h1',
  h2: 'h2',
  h2Medium: 'h2',
  h3: 'h3',
  h3Semibold: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  eyebrow: 'span',
  button: 'span',
  badge: 'span',
  navigation: 'span',
  caption1: 'span',
  caption2: 'span',
  legal1: 'span',
  legal2: 'span',
  link1: 'span',
  link2: 'span',
  link3: 'span',
};

const TypographyComponent = styled.span<{ $variant: Variants }>`
  font-family: Graphik, sans-serif;
  margin: 0;
  padding: 0;
  ${({ $variant }): FlattenInterpolation<ThemeProps<{}>> => css`
    font-size: ${theme(`TrekTypography.sizes.${$variant}.fontSize`)};
    font-style: ${theme(`TrekTypography.sizes.${$variant}.fontStyle`)};
    font-weight: ${theme(`TrekTypography.sizes.${$variant}.fontWeight`)};
    line-height: ${theme(`TrekTypography.sizes.${$variant}.lineHeight`)};
    letter-spacing: ${theme(`TrekTypography.sizes.${$variant}.letterSpacing`)};
    text-transform: ${theme(`TrekTypography.sizes.${$variant}.textTransform`, 'none')};
    color: inherit;

    ${$variant.includes('link') &&
    css`
      color: ${theme('TrekTypography.pallete.foregroundLink')};
      text-decoration-line: underline;
      cursor: pointer;
    `}
  `}
`;
TypographyComponent.defaultProps = {
  theme: DefaultTheme,
  $variant: 'body1',
};

export const Typography = React.forwardRef(
  ({ variant, component, children, ...props }: TypographyProps & React.HTMLAttributes<{}>, ref?: React.Ref<any>) => {
    const asProp: any = component ? component : variantMapping[variant];
    return (
      <TypographyComponent {...props} as={asProp} ref={ref} $variant={variant}>
        {children}
      </TypographyComponent>
    );
  }
);
