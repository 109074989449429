import React, { useState } from 'react';
import config from '@hulu/env-config';

import { useHuluSSO, LOADING_DATA } from './hooks';
import Redirect from './redirect';
import { AuthContextInterface, Roles } from './index.d';

const baseUrl = config.REACT_APP_AUTH_BASE_URL || '';

export const AuthenticationContext = React.createContext<AuthContextInterface>({
  auth: null,
  roles: [],
  hasRole: () => false,
  setRoles: () => {},
});

const getUsersRoles = (roleMap: Roles | null, usersGroups: string[]): string[] => {
  if (roleMap === null || !Array.isArray(usersGroups)) {
    return [];
  }
  let ret = [];
  for (const [role, groups] of Object.entries(roleMap)) {
    if (groups.some((group: string) => usersGroups.includes(group))) {
      ret.push(role);
    }
  }
  return ret;
};

// Authentication context provider, internally we use our hook and redirect on invalid session
export const Authentication: React.FC<{ children?: React.ReactNode; checkInterval?: number; render?: Function }> = ({
  children,
  checkInterval,
  render,
}) => {
  const [auth] = useHuluSSO({ checkInterval, baseUrl });
  const [roles, setRoles] = useState<Roles | null>(null);

  const usersRoles = getUsersRoles(roles, auth.groups);
  const hasRole = (toCheck: string | string[]): boolean => {
    if (usersRoles.length === 0) {
      return false;
    }
    if (!Array.isArray(toCheck)) {
      toCheck = [toCheck];
    }
    return toCheck.some((role: string) => usersRoles.includes(role));
  };

  return (
    <AuthenticationContext.Provider value={{ auth, hasRole, roles: usersRoles, setRoles }}>
      {auth.error && <Redirect baseUrl={baseUrl} />}
      {/* don't render children on error and until data is loaded */}
      {!auth.error && auth !== LOADING_DATA && !render && children}
      {/* pass error to render prop */}
      {auth !== LOADING_DATA && render && render(auth)}
    </AuthenticationContext.Provider>
  );
};
