import merge from 'lodash.merge';
import * as Theme from './default-theme';

type KeyTypes<T> = {
  [K in keyof T]: T[K];
}[keyof T];

// from: https://archive.is/g7VRQ
type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export type ThemeType = KeyTypes<typeof Theme>;

type PartialTheme = Partial<ThemeType>;

export type ThemeTypeAll = UnionToIntersection<KeyTypes<typeof Theme>>;

export const defaultTheme: ThemeTypeAll = Object.values(Theme).reduce((themeObject, value) => {
  return merge(themeObject, value);
}, {}) as ThemeTypeAll;

/**
 * createTheme is an utility method to generate trek theme
 * definitions based on the default theme, new definitions
 * override the default options.
 * @param newProps Theme branch to apply
 */
export const createTheme = (newProps: PartialTheme): ThemeTypeAll => {
  return merge<ThemeTypeAll, PartialTheme, PartialTheme>({} as ThemeTypeAll, defaultTheme, newProps);
};
