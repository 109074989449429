import { spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type PageHeaderTheme = ThemeBranch<{
  TrekPageHeader: {
    sizes: {
      condensed: {
        padding: string;
      };
      regular: {
        padding: string;
      };
    };
  };
}>;

export const DefaultTheme: PageHeaderTheme = {
  TrekPageHeader: {
    sizes: {
      condensed: {
        padding: `0.2em ${spacers.spacer04}`,
      },
      regular: {
        padding: `1em ${spacers.spacer04}`,
      },
    },
  },
};
