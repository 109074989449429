import React, { useContext } from 'react';
import { AuthenticationContext } from './context';

export interface AuthorizeProps {
  children: React.ReactElement<any, any> | null;
  roles: string | string[];
}

export const Authorize: React.FC<AuthorizeProps> = ({ children, roles }) => {
  const { hasRole } = useContext(AuthenticationContext);
  if (hasRole(roles)) {
    return children;
  }
  return null;
};
