import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

type Sizes = {
  fontSize: string;
  fontStyle: string;
  fontWeight: string;
  lineHeight: string;
  letterSpacing: string;
  textTransform?: string;
};

export type TypographyTheme = ThemeBranch<{
  TrekTypography: {
    pallete: {
      foreground: string;
      foregroundLink: string;
    };
    sizes: {
      h1: Sizes;
      h2: Sizes;
      h2Medium: Sizes;
      h3: Sizes;
      h3Semibold: Sizes;
      h4: Sizes;
      h5: Sizes;
      h6: Sizes;
      h7: Sizes;
      body1: Sizes;
      body2: Sizes;
      body3: Sizes;
      eyebrow: Sizes;
      button: Sizes;
      badge: Sizes;
      navigation: Sizes;
      caption1: Sizes;
      caption2: Sizes;
      legal1: Sizes;
      legal2: Sizes;
      link1: Sizes;
      link2: Sizes;
      link3: Sizes;
    };
  };
}>;

export const DefaultTheme: TypographyTheme = {
  TrekTypography: {
    pallete: {
      foreground: colors.colorFauxBlack,
      foregroundLink: colors.colorBlueBase,
    },
    sizes: {
      h1: {
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '56px',
        letterSpacing: '-0.5px',
      },
      h2: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '48px',
        letterSpacing: '-0.5px',
      },
      h2Medium: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '48px',
        letterSpacing: '-0.5px',
      },
      h3: {
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '40px',
        letterSpacing: '-0.5px',
      },
      h3Semibold: {
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '40px',
        letterSpacing: '-0.5px',
      },
      h4: {
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '30px',
        letterSpacing: '0',
      },
      h5: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      h6: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      h7: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      body1: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      body2: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '21px',
        letterSpacing: '0',
      },
      body3: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '18px',
        letterSpacing: '0.25px',
      },
      eyebrow: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '14px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      button: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '12px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      badge: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '12px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
      },
      navigation: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '14px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      caption1: {
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      caption2: {
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
      },
      legal1: {
        fontSize: '12px',
        fontStyle: 'italic',
        fontWeight: 'normal',
        lineHeight: '18px',
        letterSpacing: '0.25px',
      },
      legal2: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '18px',
        letterSpacing: '0.25px',
      },
      link1: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      link2: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '21px',
        letterSpacing: '0',
      },
      link3: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '18px',
        letterSpacing: '0.25px',
      },
    },
  },
};
