import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type StaticSearchResultsTheme = ThemeBranch<{
  TrekStaticSearchResultsContainer: {
    sizes: {
      small: {
        padding: string;
        borderRadius: string;
        maxHeight: string;
      };
      full: {
        padding: string;
        borderRadius: string;
        maxHeight: string;
      };
    };
    pallete: {
      elevation: string;
    };
  };
  TrekStaticSearchResultsListContainer: {
    typography: {
      fontFamily: string;
    };
    sizes: {
      small: {
        padding: string;
      };
      full: {
        padding: string;
      };
    };
  };
  TrekStaticSearchResultItem: {
    pallete: {
      borderColor: string;
    };
    sizes: {
      small: {
        padding: string;
      };
      full: {
        padding: string;
      };
    };
  };
  TrekStaticSearchResultTitle: {
    pallete: {
      foregroundLink: string;
    };
    sizes: {
      small: {
        fontWeight: string;
        fontSize: string;
        lineHeight: string;
        margin: string;
      };
      full: {
        fontWeight: string;
        fontSize: string;
        lineHeight: string;
        margin: string;
      };
    };
  };
  TrekStaticSearchResultLink: {
    pallete: {
      foregroundLink: string;
    };
    sizes: {
      small: {
        margin: string;
        fontSize: string;
        lineHeight: string;
      };
      full: {
        margin: string;
        fontSize: string;
        lineHeight: string;
      };
    };
  };
  TrekStaticSearchResultDescription: {
    sizes: {
      small: {
        fontSize: string;
        lineHeight: string;
      };
      full: {
        fontSize: string;
        lineHeight: string;
      };
    };
  };
  TrekStaticSearchResultsCategory: {
    sizes: {
      small: {
        padding: string;
      };
      full: {
        padding: string;
      };
    };
  };
  TrekStaticSearchResultsCategoryTitle: {
    pallete: {
      borderColor: string;
    };
    sizes: {
      small: {
        padding: string;
      };
      full: {
        padding: string;
      };
    };
  };
}>;

export const DefaultTheme: StaticSearchResultsTheme = {
  TrekStaticSearchResultsContainer: {
    sizes: {
      small: {
        padding: `0 0 ${spacers.spacer04}`,
        borderRadius: `0 0 ${spacers.spacer02} ${spacers.spacer02}`,
        maxHeight: '350px',
      },
      full: {
        padding: '0',
        borderRadius: '0',
        maxHeight: 'auto',
      },
    },
    pallete: {
      elevation:
        '0px 6px 10px 0px rgba(99, 110, 133, 0.14), 0px 1px 18px 0px rgba(99, 110, 133, 0.12), 0px 3px 5px -1px rgba(99, 110, 133, 0.2)',
    },
  },
  TrekStaticSearchResultsListContainer: {
    typography: {
      fontFamily: 'Graphik, sans-serif',
    },
    sizes: {
      small: {
        padding: `0 ${spacers.spacer06}`,
      },
      full: {
        padding: '0',
      },
    },
  },
  TrekStaticSearchResultItem: {
    pallete: {
      borderColor: colors.colorGray4,
    },
    sizes: {
      small: {
        padding: `${spacers.spacer03} 0`,
      },
      full: {
        padding: `${spacers.spacer04} 0`,
      },
    },
  },
  TrekStaticSearchResultTitle: {
    pallete: {
      foregroundLink: colors.colorBlueBase,
    },
    sizes: {
      small: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '1',
        margin: `0 0 ${spacers.spacer02}`,
      },
      full: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '1',
        margin: `0 0 ${spacers.spacer02}`,
      },
    },
  },
  TrekStaticSearchResultLink: {
    pallete: {
      foregroundLink: colors.colorGray2,
    },
    sizes: {
      small: {
        fontSize: '12px',
        lineHeight: '1',
        margin: `0 0 ${spacers.spacer01}`,
      },
      full: {
        fontSize: '14px',
        lineHeight: '1',
        margin: `0 0 ${spacers.spacer02}`,
      },
    },
  },
  TrekStaticSearchResultDescription: {
    pallete: {
      foregroundLink: colors.colorGray2,
    },
    sizes: {
      small: {
        fontSize: '14px',
        lineHeight: '1',
      },
      full: {
        fontSize: '16px',
        lineHeight: '1',
      },
    },
  },
  TrekStaticSearchResultsCategory: {
    sizes: {
      small: {
        padding: `${spacers.spacer04} 0 0`,
      },
      full: {
        padding: `${spacers.spacer04} 0 0`,
      },
    },
  },
  TrekStaticSearchResultsCategoryTitle: {
    pallete: {
      borderColor: `${colors.colorGray4}`,
    },
    sizes: {
      small: {
        padding: `${spacers.spacer04} ${spacers.spacer06}`,
      },
      full: {
        padding: `${spacers.spacer04} ${spacers.spacer04}`,
      },
    },
  },
};
