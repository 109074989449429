import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import get from 'lodash.get';
import config from '@hulu/env-config';

interface postMessageEvent {
  data?: any;
}

export const LOADING_DATA = Object.freeze({});
const unauthorizedError = { error: 'unauthorized' };
const overrideInterval = config.REACT_APP_SSO_INTERVAL && parseInt(config.REACT_APP_SSO_INTERVAL, 10);

export function useHuluSSO<UseSSOParams>({ checkInterval = 10e3, baseUrl = window.location.origin } = {}): any[] {
  const [sso, setSSO] = useState<any>(LOADING_DATA);
  // we need useRef here to avoid using it as effect dependency
  const intervalId = useRef<NodeJS.Timeout | number>(-1);
  useEffect(() => {
    const verifySSO = async () => {
      const current_valid_till = get(sso, 'valid_till', '');
      try {
        const response = await axios.get(`${baseUrl}/session`, { withCredentials: true });
        const valid_till = get(response, 'data.valid_till');
        /*
         * Here we're updating the sesion data based on the session TTL
         * this should avoid rerenders when no changes are made
         * i.e. when session doesn't change, but we're getting a new
         * object every time [with the same values].
         */
        if (valid_till !== current_valid_till) {
          setSSO(response.data);
        }
      } catch (error) {
        clearInterval(intervalId.current as number);
        if (get(error, 'response.status') !== 401) {
          console.error('Unknown Authentication error: ', error);
        }
        setSSO(unauthorizedError);
      }
    };

    // check sso data on first load
    if (sso === LOADING_DATA) {
      verifySSO();
    }

    // set interval only on successful sso auth
    if (!sso.error) {
      const id = setInterval(verifySSO, overrideInterval || checkInterval);
      intervalId.current = id;
    }

    const ssoMessageHandler = ({ data = {} }: postMessageEvent) => {
      const { type = '' } = data;
      // we got a successful login, trigger a session check
      if (type === 'sso') {
        verifySSO();
      }
    };
    window.addEventListener('message', ssoMessageHandler);

    return () => {
      clearInterval(intervalId.current as number);
      window.removeEventListener('message', ssoMessageHandler);
    };
  }, [baseUrl, checkInterval, sso]);

  return [sso];
}
