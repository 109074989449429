import { colors } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type LinkTheme = ThemeBranch<{
  TrekLink: {
    pallete: {
      foreground: string;
      errorForeground: string;
    };
    sizes: {
      fontSize: string;
      lineHeight: string;
    };
  };
}>;

export const DefaultTheme: LinkTheme = {
  TrekLink: {
    pallete: {
      foreground: colors.colorBlueBase,
      errorForeground: colors.colorRedBase,
    },
    sizes: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
};
