import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type CommunityCardTheme = ThemeBranch<{
  CommunityCard: {
    pallete: {
      background: string;
      normal: {
        borderColor: string;
      };
      outlined: {
        borderColor: string;
      };
    };
    sizes: {
      padding: string;
      normal: {
        borderRadius: string;
      };
      outlined: {
        borderRadius: string;
      };
    };
  };
}>;

export const DefaultTheme: CommunityCardTheme = {
  CommunityCard: {
    pallete: {
      background: colors.colorWhite,
      normal: {
        borderColor: colors.colorWhite,
      },
      outlined: {
        borderColor: colors.colorGray4,
      },
    },
    sizes: {
      padding: spacers.spacer05,
      normal: {
        borderRadius: '0',
      },
      outlined: {
        borderRadius: '2px',
      },
    },
  },
};
