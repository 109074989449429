import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type TableTheme = ThemeBranch<{
  TrekTableContainer: {
    pallete: {
      background: string;
      elevation: string;
    };
  };
  TrekTable: {
    pallete: {
      background: string;
    };
    sizes: {
      padding: string;
    };
  };
  TrekTableCell: {
    pallete: {
      foreground: string;
    };
    sizes: {
      fontSize: string;
      lineHeight: string;
      height: string;
    };
  };
  TrekTableRow: {
    pallete: {
      borderColor: string;
    };
  };
  TrekTablePaginationActions: {
    pallete: {
      foreground: string;
      background: string;
    };
    sizes: {
      padding: string;
      fontSize: string;
      lineHeight: string;
      height: string;
      paddingButton: string;
      heightButton: string;
    };
  };
  TrekTablePaginationActionsPageSelector: {
    pallete: {
      borderColor: string;
    };
    sizes: {
      height: string;
      padding: string;
    };
  };
}>;

export const DefaultTheme: TableTheme = {
  TrekTableContainer: {
    pallete: {
      background: colors.colorWhite,
      elevation: '0px 2px 35px 0px rgba(0, 0, 0, 0.1)',
    },
  },
  TrekTable: {
    pallete: {
      background: colors.colorWhite,
    },
    sizes: {
      padding: '26px',
    },
  },
  TrekTableCell: {
    pallete: {
      foreground: colors.colorFauxBlack,
    },
    sizes: {
      fontSize: '14px',
      lineHeight: '14px',
      height: '64px',
    },
  },
  TrekTableRow: {
    pallete: {
      borderColor: colors.colorGray3,
    },
  },
  TrekTablePaginationActions: {
    pallete: {
      foreground: colors.colorFauxBlack,
      background: colors.colorWhite,
    },
    sizes: {
      padding: spacers.spacer05,
      fontSize: '14px',
      lineHeight: '21px',
      height: '21px',
      paddingButton: '0 20px',
      heightButton: '24px',
    },
  },
  TrekTablePaginationActionsPageSelector: {
    pallete: {
      borderColor: colors.colorGray3,
    },
    sizes: {
      height: '40px',
      padding: spacers.spacer02,
    },
  },
};
