import React, { SVGAttributes } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { theme } from 'styled-tools';
import { themeUtils } from '@hulu-react-style-components/util';

const DefaultTheme = themeUtils.Theme.MonochromeLogo;

const Container = styled.svg`
  color: var(--primary-color, ${theme('TrekMonochromeLogo.pallete.foreground')});
`;
Container.defaultProps = {
  theme: DefaultTheme,
};

const MonochromeLogo = React.forwardRef(
  ({ className, ...props }: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <Container
        viewBox="0 -2 61 24"
        fill="currentcolor"
        width="61px"
        height="24px"
        aria-hidden="true"
        ref={ref}
        className={cx('TrekMonochromeLogo-root', className)}
        {...props}
      >
        <title>Hulu</title>
        <path
          d="M9.57 6.241H6.47c-1.088 0-1.634.293-1.634.293V0H0v20h4.835v-8.07c0-.714.58-1.292 1.295-1.292h2.814c.716 0 1.295.578 1.295 1.292V20h4.836v-8.695c0-3.657-2.444-5.064-5.505-5.064zm46.48 0v8.07c0 .714-.579 1.292-1.294 1.292h-2.814a1.293 1.293 0 0 1-1.295-1.293V6.241H45.81v8.472c0 3.463 2.222 5.287 5.506 5.287h4.735v-.025c3.025 0 4.836-2.146 4.836-5.262V6.24H56.05zm-27.277 8.07c0 .714-.58 1.292-1.296 1.292h-2.813a1.293 1.293 0 0 1-1.296-1.293V6.241h-4.836v8.472c0 3.463 2.222 5.287 5.506 5.287h4.735v-.025c3.025 0 4.836-2.146 4.836-5.262V6.24h-4.836v8.07zM37.294 20h4.837V0h-4.837v20z"
          fillRule="evenodd"
        />
      </Container>
    );
  }
);

export default MonochromeLogo;
