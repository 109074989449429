import { colors, spacers } from '@hulu/design-tokens';
import { ThemeBranch } from './types';

export type BreadcrumbsTheme = ThemeBranch<{
  TrekBreadcrumbs: {
    pallete: {
      foreground: string;
      foregroundLastItem: string;
      foregroundHover: string;
      separator: string;
    };
    sizes: {
      height: string;
      fontSize: string;
      letterSpacing: string | number;
      lineHeight: string;
      separatorWidth: string;
      padding: string;
      itemPadding: string;
    };
  };
}>;

export const DefaultTheme: BreadcrumbsTheme = {
  TrekBreadcrumbs: {
    pallete: {
      foreground: colors.colorFauxBlack,
      /** foreground for last item in breadcrumbs */
      foregroundLastItem: colors.colorGray2,
      foregroundHover: colors.colorFauxBlack,
      separator: colors.colorGray2,
    },
    sizes: {
      height: spacers.spacer06,
      fontSize: '12px',
      letterSpacing: 0,
      lineHeight: spacers.spacer04,
      separatorWidth: `10px`,
      padding: `${spacers.spacer01} 0`,
      itemPadding: spacers.spacer01,
    },
  },
};
